import FooterComponent from "../../component/guest/footer/FooterComponent";
import HeaderComponent from "../../component/guest/header/HeaderComponent";
import SidebarComponent from "../../component/guest/sidebar/SidebarComponent";
import "./style.css";

const LayoutGuest: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <div>
      <HeaderComponent />
      <SidebarComponent />
      <main>{children}</main>
      <FooterComponent />
    </div>
  );
};

export default LayoutGuest;
