import { Col, Row } from "antd";
import "./style.css";
import Logo4s from "../../../assets/images/logo_4s.png";
import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";

const FooterComponent: React.FC = () => {
  const elementsToAnimate = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    const elements = elementsToAnimate.current.filter((el) => el !== null);
    elements.forEach((element) => {
      observer.observe(element);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <footer className="footer-guest">
      <Row
        className="row-footer animate-on-scroll"
        ref={(el) => {
          if (el) elementsToAnimate.current[0] = el;
        }}
      >
        <Col xl={6} md={24} sm={24} xs={24} className="col-image-footer">
          <img alt="" src={Logo4s} width={150} height={150} />
        </Col>
        <Col xl={4} sm={12} xs={24} className="col-footer">
          <div className="col-footer-box">
            <div style={{ textAlign: "left" }}>
              <div className="title-footer-column-2">4S Digital Services</div>{" "}
              <br />
              <div>
                {" "}
                <Link to="/" className="link_footer_guest">
                  Contact us
                </Link>
              </div>
              <br />
              <div>
                {" "}
                <Link to="/activity-reports" className="link_footer_guest">
                  Careers
                </Link>
              </div>
              <br />
              <div>
                <Link to="/recruitment" className="link_footer_guest">
                  Our location
                </Link>
              </div>
            </div>
          </div>
        </Col>
        <Col xl={4} md={24} sm={24} xs={24} className="col-footer">
          <div className="col-footer-box">
            <div style={{ textAlign: "left" }}>
              <div className="title-footer-column-2">Home</div> <br />
              <div>
                {" "}
                <Link to="/" className="link_footer_guest">
                  Our solutions
                </Link>
              </div>
              <br />
              <div>
                {" "}
                <Link to="/activity-reports" className="link_footer_guest">
                  Technology
                </Link>
              </div>
              <br />
              <div>
                <Link to="/recruitment" className="link_footer_guest">
                  Industry
                </Link>
              </div>
            </div>
          </div>
        </Col>
        <Col xl={4} md={24} sm={24} xs={24} className="col-footer">
          <div className="col-footer-box">
            <div style={{ textAlign: "left" }}>
              <div className="title-footer-column-2">Useful links</div> <br />
              <div>
                {" "}
                <Link to="/" className="link_footer_guest">
                  Privacy Policy
                </Link>
              </div>
              <br />
              <div>
                {" "}
                <Link to="/activity-reports" className="link_footer_guest">
                  Accelerators
                </Link>
              </div>
              <br />
              <div>
                <Link to="/recruitment" className="link_footer_guest">
                  Blog
                </Link>
              </div>
            </div>
          </div>
        </Col>
        <Col xl={4} md={24} sm={24} xs={24} className="col-footer">
          <div className="col-footer-box">
            <div style={{ textAlign: "left" }}>
              <div className="title-footer-column-2">Connect with us</div>{" "}
              <br />
              <div>
                {" "}
                <Link to="/" className="link_footer_guest">
                  Linkedln
                </Link>
              </div>
              <br />
              <div>
                {" "}
                <Link to="/activity-reports" className="link_footer_guest">
                  Facebook
                </Link>
              </div>
              <br />
              <div>
                <Link to="/recruitment" className="link_footer_guest">
                  Youtube
                </Link>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </footer>
  );
};

export default FooterComponent;
