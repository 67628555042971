import React, { useState, useEffect } from "react";
import Logo4s from "../../assets/images/logo_no_text_white.png";

const ScrollToTop: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 10) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (!isVisible) return null;
  return (
    <button
      onClick={scrollToTop}
      style={{
        position: "fixed",
        bottom: "40px",
        right: "40px",
        padding: "5px",
        borderRadius: "5px",
        backgroundColor: "white",
        boxShadow: "0px -1px 15.9px 0px rgba(0, 0, 0, 0.15)",
        border: "none",
        cursor: "pointer",
      }}
    >
      <img alt="" src={Logo4s} width={35} />
    </button>
  );
};

export default ScrollToTop;
