import React from "react";
import { Link } from "react-router-dom";
import "./style-menu.css";

interface MenuItem {
  title: string;
  path: string;
  subItems?: string[];
}

const Menu: React.FC<{ item: MenuItem }> = ({ item }) => {
  return (
    <nav className="menu">
      <Link to={item.path} className="link-menu">
        {item.title}
      </Link>
    </nav>
  );
};

export default Menu;
