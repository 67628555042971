import { Col, Row } from "antd";
import "./style.css";
import Logo4sDigitalServices from "../../../assets/images/logo_4s.png";
import Menu from "./Menu";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const HeaderComponent: React.FC = () => {
  const menuItems = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "Services",
      path: "/services",
    },
    {
      title: "Industries",
      path: "/industries",
    },
    {
      title: "Technologies",
      path: "/technologies",
    },
    {
      title: "About Us",
      path: "/about-us",
    },
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const gotoContactUs = () => {
    const element = document.getElementById("form-contact");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <header className="section-header">
      <Row>
        <Col lg={5} xs={9} sm={8} className="section-header-col">
          <img alt="" src={Logo4sDigitalServices} className="main-logo" />
        </Col>
        {menuItems.map((item, index) => (
          <Col key={index} lg={3} className="section-header-col menu-res">
            <Menu item={item} />
          </Col>
        ))}
        {isMobile && (
          <Col lg={4} xs={6} sm={8} className="section-header-col">
            <div onClick={toggleMenu}>
              <FontAwesomeIcon
                icon={faBars}
                style={{ fontSize: 30, cursor: "pointer" }}
              />
            </div>
          </Col>
        )}
        <Col lg={4} xs={9} sm={8} className="section-header-col contact-us">
          <button className="btn-contact-us" onClick={gotoContactUs}>
            <span className="content-btn-contact-us">CONTACT US</span>
          </button>
        </Col>
      </Row>
      {isMenuOpen && (
        <div className="menu-overlay">
          <div className="menu-content">
            <div className="close-button" onClick={closeMenu}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{ fontSize: 20, cursor: "pointer" }}
              />
            </div>
            <div style={{ paddingLeft: "5%", paddingTop: "10%" }}>
              {" "}
              {menuItems.map((item, index) => (
                <div style={{ marginBottom: 20 }}>
                  <Link to={item.path} className="link-menu-mobile">
                    {item.title}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default HeaderComponent;
