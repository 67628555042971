import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/landing/Home";
import LayoutGuest from "./layout/guest/LayoutGuest";
import "./App.css";
import ScrollToTop from "./helper/common/ScrollToTop";

const App: React.FC = () => {
  return (
    <div className="App scroll-smooth md:scroll-auto">
      <BrowserRouter>
        <Suspense>
          <Routes>
            <Route
              path="/"
              element={
                <LayoutGuest>
                  <Home />
                </LayoutGuest>
              }
            />
          </Routes>
        </Suspense>
      </BrowserRouter>{" "}
      <ScrollToTop />
    </div>
  );
};

export default App;
